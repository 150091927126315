'use client';

/* @jsxImportSource react */
import { StaticErrorContent } from '@packages/modules/src/StaticErrorContent/StaticErrorContent';
import { PageMetaData } from '@packages/modules/src/PageMetaData/PageMetaData';
import useIntl from 'react-intl/src/components/useIntl';
import { useEffect, useState } from 'react';
import type { CmsData } from '@packages/cms-components/interfaces/webapp_json';
import { Content } from '@packages/cms-components/src/components/CmsContent/Content';

const CmsErrorContent = ({ error, locale }: { error: any; locale: any }) => {
  /**
   * this is a try to fetch the error page content from the cms but while this is a client component the data cannot be fetched from the bucket
   */
  const [cmsContent, setCmsContent] = useState<CmsData>();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  const { formatMessage } = useIntl();
  useEffect(() => {
    fetch('/api/cmsError').then((data) => {
      if (!data.ok) {
        return;
      }
      data.json().then((jsonData) => {
        setCmsContent(jsonData as CmsData);
      });
    });
  }, [locale]);

  if (cmsContent) {
    return <Content data={cmsContent} />;
  }

  return (
    <>
      <PageMetaData page={{ type: 'error500', template: 'error500' }} />
      <StaticErrorContent
        title={formatMessage({
          id: 'page.500.title',
          defaultMessage: '500 - ES IST EIN FEHLER AUFGETRETEN',
        })}
        description={formatMessage({
          id: 'page.500.description',
          defaultMessage: 'Leider ist hier ein Fehler aufgetreten.',
        })}
        info={formatMessage({
          id: 'page.500.info',
          defaultMessage:
            'Doch vielleicht haben wir trotzdem etwas für dich. Besuche unsere Startseite.',
        })}
        buttonLabel={formatMessage({
          id: 'page.500.button.label',
          defaultMessage: 'zur Startseite',
        })}
      />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default CmsErrorContent;
