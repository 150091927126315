'use client';

import { Box, Button, Typography } from '@packages/shared';

/**
 * This component shows static content for an error page
 *
 * @param title contains title
 * @param description contains description
 * @param info contains further information
 * @param buttonLabel contains label of back button
 * @returns JSX.Element to be placed in Error Pages
 */
export type StaticErrorContentProps = {
  title: string;
  description?: string;
  info?: string;
  buttonLabel?: string;
};

export const StaticErrorContent = ({
  title,
  description,
  info,
  buttonLabel,
}: StaticErrorContentProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      pt: 2,
    }}
  >
    <Typography
      align="center"
      sx={{
        mb: 3,
        fontWeight: 600,
        '&::after': {
          content: '""',
          width: '3.875rem',
          borderBottom: '0.125rem solid',
          borderColor: 'primary.main',
          display: 'block',
          marginTop: 1,
          marginBottom: 1,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }}
      variant="h2"
    >
      {title}
    </Typography>
    {description && (
      <Typography align="center" sx={{ mb: info ? 0 : 4 }} variant="body1">
        {description}
      </Typography>
    )}
    {info && (
      <Typography align="center" sx={{ mb: 4 }} variant="body1">
        {info}
      </Typography>
    )}
    {buttonLabel && (
      <Button color="primary" href="/">
        {buttonLabel}
      </Button>
    )}
  </Box>
);
